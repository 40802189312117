<template>
  <div>
    <div class="panel">
      <PanelTitle title="用户任务" :title-class="['fs20']"/>
      <el-menu  :default-active="listType" mode="horizontal" active-text-color="#FF7519" @select="menuSelect">
        <el-menu-item :index="item.type" v-for="(item, index) in menuList" :key="index">
          {{item.text}}
        </el-menu-item>
      </el-menu>
    </div>
    <div class="task-list" v-if="taskList.length > 0">
      <div class="task-item" v-for="(item, index) in taskList" :key="index">
        <div class="flex justify-between">
          <div class="flex items-center">
            <LoadImage class="avatar" :src="item.logo"></LoadImage>
            <div>
              <div class="name">{{item.nickName}}</div>
              <div class="id">ID：{{item.taskUserId}}</div>
            </div>
          </div>
          <div class="status">
            <div class="desc">{{item.statusDesc}}</div>
            <div class="time">{{item.statusTime}}</div>
          </div>
        </div>
        <div class="bottom-row">
          <div class="flex items-center">
            <span class="mr4">{{item.countDownDesc}}</span>
            <CountDown v-if="item.countDown > 0" :time="item.countDown*1000" color="#FF7519"></CountDown>
          </div>
          <el-button type="primary" size="mini" class="fs14" :disabled="!item.action.enable" @click="handleBtnAction(item.action)">{{item.action.text}}</el-button>
        </div>
      </div>
    </div>
    <div v-else class="panel mt16">
      <Empty/>
    </div>
    <div class="flex justify-center mt24">
      <Pagination :current-page="curPage + 1" @next-click="nextPage" @prev-click="prevPage"/>
    </div>
  </div>
</template>

<script>
import PanelTitle from '@/components/PanelTitle'
import LoadImage from '@/components/LoadImage'
import CountDown from '@/components/CountDown'
import Empty from '@/components/Empty'
import Pagination from '@/components/Pagination'
import { userTaskPanelsApi } from '@/api/task.js'
export default {
  components: {
    PanelTitle,
    LoadImage,
    CountDown,
    Empty,
    Pagination
  },
  data() {
    return {
      menuList: [
        {text: '进行中', type: '0'},
        {text: '已完成', type: '1'},
      ],
      listType: '0',
      taskId: '',
      taskList: [],
      curPage: 0
    }
  },
  created() {
    this.taskId = this.$route.query.taskId
    this.listType = this.$route.query.type || '0'
    this.getTaskList()
  },
  methods: {
    getTaskList() {
      const formData = {
        startPage: this.curPage,
        type: Number(this.listType),
        taskId: this.taskId
      }
      userTaskPanelsApi(formData).then(res => {
        console.log('任务列表', res)
        const list  = res.data.userTasks || []
        if(list.length > 0 || this.curPage == 0) {
          this.taskList = list
        } else {
          this.$tips({type: 'warning', message: '没有更多数据了'})
          this.curPage--
        }
      }).catch(err => {
        this.$tips({type: 'error', message: err})
      })
    },
    menuSelect(e) {
      this.listType = e
      this.getTaskList()
    },
    nextPage() {
      this.curPage++
      this.getTaskList()
    },
    prevPage() {
      this.curPage = this.curPage > 0 ? this.curPage - 1 : 0
      this.getTaskList()
    },
    handleBtnAction(actionInfo) {
      if(actionInfo.act == 2) {
        this.$router.push(`/taskReview?taskId=${this.taskId}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.task-list {
  display: flex;
  flex-wrap: wrap;
  .task-item {
    width: 390px;
    background-color: #fff;
    padding: 24px;
    margin: 12px 12px 0 0;
    border-radius: 16px;
    &:nth-child(3n) {
      margin-right: 0;
    }
    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin-right: 12px;
      overflow: hidden;
    }
    .name {
      font-size: 16px;
      font-weight: bold;
      max-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 8px;
    }
    .id {
      font-size: 12px;
      color: #B0B3BF;
    }
    .status {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      font-size: 12px;
      color: #B0B3BF;
    }
    .bottom-row {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #f5f5f5;
      padding-top: 12px;
      margin-top: 12px;
      font-size: 12px;
      color: #666;
    }
  }
}
</style>